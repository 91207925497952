<template>
    <div id='opportunities'>
        <CRow>
        <CCol md="2">
        </CCol>
        <CCol md="8">

        <ul v-if="fetching">
            <div class="center-align">
                <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>fetching opportunities</p>
            </div>
        </ul>
        <div>
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/><strong> Opportunities </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CListGroup >
                            <div v-for="(opportunity) in newOpportunities" :key="opportunity.opportunityId">
                                <OpportunityRow 
                                    :opportunityId="opportunity.opportunityId" 
                                    :email="opportunity.email" 
                                    :phone="opportunity.phone" 
                                    :enquirerName="opportunity.enquirerName"
                                    :opportunityType="opportunity.opportunityType"
                                    :opportunityStatus="opportunity.opportunityStatus"
                                    :opportunityMessage="opportunity.opportunityMessage"
                                    :source="opportunity.source"
                                    :sourceDescription="opportunity.sourceDescription"
                                    :sourceUrl="opportunity.sourceUrl"
                                    :contactId="opportunity.contactId"
                                    :createdDate="opportunity.createdDate" />
                            </div>
                            <div v-for="(opportunity) in notNewOpportunities" :key="opportunity.opportunityId">
                                <OpportunityRow 
                                    :opportunityId="opportunity.opportunityId" 
                                    :email="opportunity.email" 
                                    :phone="opportunity.phone" 
                                    :enquirerName="opportunity.enquirerName"
                                    :opportunityType="opportunity.opportunityType"
                                    :opportunityStatus="opportunity.opportunityStatus"
                                    :opportunityMessage="opportunity.opportunityMessage"
                                    :source="opportunity.source"
                                    :sourceDescription="opportunity.sourceDescription"
                                    :sourceUrl="opportunity.sourceUrl"
                                    :contactId="opportunity.contactId"
                                    :createdDate="opportunity.createdDate" />
                            </div>
                        </CListGroup>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
        </CCol>
                </CRow>
    </div>
</template>

<script>
// import { BIconEnvelope, BIconTelephone } from 'bootstrap-vue'
// import { cisMobile, cibMailRu, cilPencil } from '@coreui/icons'
// import { Icon } from 'ant-design-vue';
import axios from 'axios'
// import { StarOutlined } from '@ant-design/icons';
// import { Icon, message } from 'ant-design-vue';
import OpportunityRow from '@/components/OpportunityRow.vue'



export default {
    name: 'Opportunities',
    components: {
        OpportunityRow
        // BIconEnvelope,
        // BIconTelephone
        // Icon
        // StarOutlined
    },
    data() {
        return {
            opportunities: null,
            newOpportunities: null,
            notNewOpportunities: null,
            fetching: true
        }
    },
    // icons: { cisMobile, cibMailRu, cilPencil },
    methods: {
        fetchOpportunities () {
            console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
            const url = process.env.VUE_APP_CRM_API_ROOT + '/opportunities';
            console.log('fetch opportunities')
            //let token = this.$http.defaults.headers.common['Authorization']
            //console.log(' use Token: ' + token)
            this.fetching = true;

            this.$cognitoAuth.getIdToken((err, result) => {
                if (err) { 
                    console.error('Opportunities, error fetching token: ' + err)
                    this.error = err;
                    delete this.$http.defaults.headers.common['Authorization'];
                    this.fetching = false;
                } else {
                    // const url = config.s3SignedUrl;
                    console.log('Opportunities, axios token: ' + result)
                    this.$http.defaults.headers.common['Authorization'] = result;
                    // let headers = {
                    //         "Access-Control-Allow-Origin": "*"
                    // };
                    // axios({ method: 'get', url: url, headers: headers})
                    axios({ method: 'get', url: url})
                    // axios({ method: 'get', url: url})
                        .then(response => {
                            // var options = { headers: { 
                            //     'Content-Type': file.type,
                            //     'Access-Control-Allow-Origin': '*' 
                            // }}
                            console.log('fetch opportunities, response: ' + response)
                            this.opportunities = response.data
                            this.fetching = false;
                            this.newOpportunities = [];
                            this.notNewOpportunities = [];
                            if (this.opportunities) {
                                console.log('fetch opportunities, split new opportunities');
                                for(var i = 0; i < this.opportunities.length; i++) {
                                    console.log('processing opportunity ' + i);
                                    var o = this.opportunities[i];
                                    if (o.opportunityStatus == 'New') {
                                        console.log('this is new opportunity');
                                        this.newOpportunities.push(o)
                                    } else {
                                        console.log('this is not a new opportunity');
                                        this.notNewOpportunities.push(o)
                                    }
                                }
                            }
                            //axios.put(x.data.uploadURL, file, options)
                        })
                        .then(status => {
                            console.log('fetch opportunities, axios status: ' + status)
                            this.status = status;
                        })
                        .catch(err => {
                            this.fetching = false;
                            console.log('fetch opportunities, axios err: ' + err)
                            //console.log('fetch opportunities, axios err.response: ' + err.response)
                            console.log('err properties...')
                            Object.keys(err).forEach((prop)=> console.log(prop));
                            console.log('err.response properties...')
                            //Object.keys(err.response).forEach((prop)=> console.log(prop));
                            console.log('fetch opportunities, axios err.isAxiosError: ' + err.isAxiosError)
                            console.log('fetch opportunities, axios err.toJSON: ' + err.toJSON)
                            console.log('fetch opportunities, axios err.request: ' + err.request)
                            this.error = err;
                            console.log('err.request properties...')
                            Object.keys(err.request).forEach((prop)=> console.log(prop));
                            // if (err.isAxiosError) {
                            //     var concatMessages = '';
                            //     //Object.entries(err.response.data.errors).forEach((prop) => concatMessages += value);
                            //     this.$store.commit("errorMessage", concatMessages);
                            //     this.$store.commit("errorModal", true);
                            // }

                        })
                }
            })
        }
    },
    rowClicked (item, index) {
        console.log('clicked row...')
      this.$router.push({path: `opportunity/${index + 1}`})
    },

    mounted () {
        console.log('Opportunities:mounted')
        this.fetchOpportunities()

    },
    created () {
        console.log('Opportunities:created')
    },
    updated () {
        console.log('Opportunities:updated')
    }
}

</script>


<style scoped>

</style>
