<template>
    <div id='jobRow'>
        <CListGroupItem
            :to="{ name: 'opportunity', params: { opportunityId: opportunityId }}"
            class="flex-column align-items-start list-item"
        >
            <CRow>
                <CCol class="text-truncate">
                    <div class="d-flex w-100 justify-content-between">
                    <span>
                        <span class="indicator" v-if="opportunityStatus == 'New'"><CBadge color="danger">New</CBadge></span>
                        <span class="indicator" v-else><CBadge color="primary">{{opportunityStatus}}</CBadge></span>
                        <span class="indicator"><CBadge color="secondary">from {{opportunityType}}</CBadge></span>
                        <span class="indicator" v-if="email && email != ''"><a-icon type="mail" /></span>
                        <span class="indicator" v-if="phone && phone != ''"><a-icon type="phone" /></span>
                    </span>
                    <small>{{ createdDate | formatDateAgo }}</small>
                    </div>

                    <span><strong>{{ enquirerName }}</strong> {{ opportunityMessage }}</span>
                </CCol>
            </CRow>
        </CListGroupItem>
    </div>
</template>

<script>

export default {
    name: 'OpportunityRow',
    components: {
        
    },
    props: {
        opportunityId: Number,
        email: String,
        phone: String,
        enquirerName: String,
        opportunityType: String,
        opportunityStatus: String,
        opportunityMessage: String,
        source: String,
        sourceDescription: String,
        sourceUrl: String,
        contactId: Number,
        createdDate: String
    }
}

</script>

<style>
.indicator {
    margin-right: 10px;
}

.list-item {
    padding: 10px;
}
</style>
